
					@import './src/sass/variables';
					@import './src/sass/mixins';
				













































































.current-file {
	flex: 1 1 0%;

	li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-bottom: $default_padding / 2;

		.button::v-deep {
			margin-left: 10px;

			button {
				background-color: $color__dark;
				padding: 5px;

				&:hover {
					background-color: rgba($color: $color__dark, $alpha: 0.8);
				}
			}
		}
	}
}

.dropzone::v-deep {
	margin-bottom: $default_padding;
}

.block-inner::v-deep {
	ul {
		list-style-type: none;
		padding-left: 0;

		a {
			text-decoration: none;
			color: $color__text;
		}
	}
}

.inline-label {
	flex: 1;
	margin-bottom: 0;

	span:first-child {
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}
}

label {
	text-align: center;
	cursor: pointer;

	input {
		display: none;
	}
}
